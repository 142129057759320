.aboutMaleInfertility {
  display: flex;
  justify-content: space-between;

  @include tab() {
    display: block;
  }

  &__text {
    margin: 0 50px 0 0;
    flex: 1;

    @include sp() {
      margin: 0;
    }
  }

  &__graph {
    width: 330px;
    text-align: center;

    @include tab() {
      width: 100%;
      max-width: 330px;
      margin: 15px auto 0;
    }

    & img {
      width: 100%;

      @include sp() {
        max-width: 265px;
      }
    }
  }
}
