.contact {
  &__link {
    display: flex;
    justify-content: center;

    &__item {
      background: #e71f19;
      height: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 1.6rem;
      color: #fff;
      width: 280px;
      margin: 0 0 0 10px;

      & > br{
        @include otherSp{
          display: none;
        }
      }

      @include sp{
        font-size: 1.4rem;
      }

      &>img {
        display: block;
        margin: 0 10px 0 0;
      }
    }
  }

  &__attention {
    font-size: 1.4rem;
    margin: 60px 0 40px;

    & span {
      color: #e71f19;
    }
  }

  &--form {
    display: flex;
    flex-wrap: wrap;
    margin: 0 0 40px;
  }

  &__title {
    width: 35%;
    padding: 20px 0 20px 20px;
    border-bottom: solid 1px #ddd;
    display: flex;
    align-items: center;
    font-size: 1.4rem;
    line-height: 1;
    color: #333;

    @include sp{
      width: 100%;
      padding: 20px 0 0;
      border-bottom: 0;
    }

    &:last-of-type {
      border-bottom: 0;
    }
  }

  &__input {
    width: 65%;
    padding: 20px 0 20px 0px;
    border-bottom: solid 1px #ddd;
    display: flex;
    flex-wrap: wrap;
    font-size: 1.4rem;
    line-height: 1.8;
    color: #333;

    @include sp{
      width: 100%;
      padding: 20px 0;
    }

    &:last-of-type {
      border-bottom: 0;
    }

    &__radio {
      width: 100%;
      display: block;
      margin: 0 0 10px;

      &:last-child {
        margin: 0;
      }
    }

    &__text {
      border: 0;
      background: #ddd;
      padding: 10px;
      width: 50%;
      font-size: 1.4rem;

      &--mail {
        @extend .contact__input__text;
        width: 100%;
      }

    }

    &--hide{
      display: none;
    }

    &__textarea {
      border: 0;
      background: #ddd;
      padding: 10px;
      width: 100%;
      font-size: 1.4rem;
    }

    &__select {
      font-size: 1.4rem;
    }
  }

  &__privacy {
    padding: 40px;
    background-color: #eee;
    margin: 0 0 40px;

    @include sp{
      padding: 20px;
    }

    &__heading {
      font-size: 1.6rem;
      font-weight: bold;
      margin: 0 0 40px;
      text-align: center;

      @include sp{
        margin: 0 0 20px;
      }
    }

    &__contents {
      padding: 20px;
      background-color: #fff;
      border: solid 1px #ddd;
      font-size: 1.2rem;
      line-height: 1.6;
      color: #333;
      height: 300px;
      overflow: scroll;

      &--information {
        margin: 20px 0 0 0;
        text-align: right;
      }
    }
  }

  &__submit {
    padding: 40px;
    border: dashed 1px #e71f19;
    text-align: center;

    &__heading {
      font-size: 1.4rem;
      margin: 0 0 20px;
    }

    &__check {
      font-size: 1.4rem;
      position: relative;
    }
  }

  &__btn {
    display: flex;
    justify-content: center;

    &--submit {
      width: 400px;
      display: block;
      margin: 40px 10px 0;
      font-size: 1.6rem;
      background-color: $baseColor;
      border: solid 1px $baseColor;
      padding: 20px;

      @include sp{
        width: 100%;
        margin: 20px 0 0;
      }

      &:hover {
        background-color: $subColor;
        border: solid 1px $subColor;
      }
    }

    &--back {
      @extend .contact__btn--submit;
      background-color: #fff;
      border: solid 1px $baseColor;
      color: #333;
      text-align: center;

      &:hover {
        background-color: #f7f3da;
      }
    }
  }

  &__tel {
    text-align: center;

    &__btn {
      display: inline-block;
      width: 300px;
      background: #e71f19;
      padding: 20px;
      color: #fff;
      font-size: 1.4rem;
      text-align: center;
      margin: 0 20px 0 0;

      @include sp{
        width: 100%;
      }

      &--number {
        display: block;
        font-size: 1.6rem;
        font-weight: bold;
        margin-top: 5px;
      }
    }

    &__description {
      display: block;
      margin: 10px 0 0 0;
      padding: 5px 0 0 0;
      border-top: solid 1px #fff;
    }

    &__time {
      display: flex;
      justify-content: center;
      margin: 20px 0 0;
      font-size: 1.4rem;
      line-height: 1.4;
      color: #333;

      &__description {
        text-align: right;
        padding: 0 0 0 20px;
        margin: 0 0 0 20px;
        display: block;
        color: $subColor;
        font-weight: bold;
        border-left: solid 1px #333;
      }
    }
  }

  &__attention {
    margin: 40px 0 0 0;
    font-size: 1.4rem;
    line-height: 1.6;
    color: #555;
    &__heading{
      font-weight: bold;
      font-size: 1.6rem;
      margin: 0 0 5px;
      color: #e71f19;
    }
    &--color{
      color: #e71f19;
    }
  }

  &__thanks {
    margin: 100px 0;
    text-align: center;
    font-size: 1.6rem;
  }

}

[data-required] {
  &:after {
    content: "必須";
    display: inline-block;
    color: #e71f19;
    margin: 0 10px;
    font-size: 1rem;
    border: 1px solid #e71f19;
    padding: 2px 4px;
    border-radius: 3px;
  }
}

// mw wp form

.mw_wp_form .error {
  margin: 10px 0 0;
  width: 100%;
}

.mw_wp_form .contact__submit__check .error {
  position: absolute;
  width: auto;
  top: calc(100% + 10px);
  left: 0;
  right: 0;
  margin: auto;
}
