.feature {
  display: flex;
  justify-content: space-between;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  flex-wrap: wrap;
  max-width: calc(1200px - 140px);

  &__item {
    width: calc((100% / 6) - 1px);
    position: relative;
    background: #fff;

    @include tab() {
      width: calc((100% / 3) - 1px);
      margin: 0 0 1px 0;
    }

    &::after {
      content: "";
      display: block;
      height: 0px;
      padding-top: 100%;
    }

    &--inner {
      position: absolute;
      width: 100%;
      top: 50%;
      left: 50%;
      transform: translateY(-50%) translateX(-50%);
    }

    &__img {
      width: 54px;
      height: 54px;
      margin: 0 auto 1vw;
      display: flex;
      justify-content: center;
      align-items: center;

      @include imgH();

      @include sp() {
        width: 32px;
        height: 32px;
        margin: 0 auto 15px;
      }
    }

    &__text {
      font-size: 1.4rem;
      text-align: center;
      color: #333;
      line-height: 1.6;

      @include sp() {
        font-size: 1.2rem;
        line-height: 1.4;
      }
    }
  }
}
