.description {
  &__img {
    margin: 30px 0 0;
    width: 100%;
    height: 400px;
    background: url('../img/description__img.jpg');
    background-size: cover;
    background-position: center;

    @include sp() {
      margin: 25px -25px 0 -25px;
      width: 100vw;
      height: auto;
    }

    &:after {

      @include sp() {
        content: "";
        display: block;
        padding-top: 60%;
      }
    }
  }
}
