.footer {
  margin: 0 0 0 ($sideNaviWidth + 1);
  background: #555;
  padding: 100px 70px;
  display: flex;
  color: #fff;
  position: relative;

  @include tab() {
    padding: 25px;

    @include sp() {
      display: block;
      margin: 0;
      padding: 25px 25px 100px;
    }
  }


  &__about {
    width: 50%;
    text-align: center;

    @include tab() {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: flex-start;
    }

    &__guide{
      padding:20px;
      margin: 0 0 20px;
      background-color: #666;
      @include tab{
        width: 100%;
      }
      &__heading{
        font-size: 1.6rem;
        font-weight: bold;
        margin: 0 0 10px;
      }
      &__text{
        font-size: 1.4rem;
      line-height: 2;
      }
    }

    &__img {
      @include tab() {
        width: 50%;
      }
    }

    &__contact {
      margin: 30px 0;

      @include tab() {
        width: 50%;
        margin: 0;
      }

      &__text {
        font-size: 1.4rem;

        @include sp() {
          font-size: 1rem;
        }
      }

      &__tel {
        display: inline-block;
        font-size: 2.4rem;
        padding: 10px 0 0;
        margin: 10px 0 0;
        border-top: solid 1px #fff;
        color: #fff;
        font-weight: bold;

        @include tab() {
          font-size: 2rem;

          @include sp() {
            font-size: 1.5rem;
            letter-spacing: -0.5px;
          }
        }
      }
    }

    &__address {
      font-size: 1.6rem;
      margin: 0 0 30px;

      @include tab() {
        width: 100%;
        margin: 15px 0;
        font-size: 1.4rem;

        @include sp() {
          font-size: 1.1rem;
        }
      }
    }

    &__practiceTime {
      &--wrap {
        @include tab() {
          width: 100%;
        }
      }

      display: flex;

      &--col {
        width: calc(100% /9);
        text-align: center;
        border-left: solid 1px #000;

        @include sp() {
          width: calc(100% /10);
        }
      }

      &--header {
        font-size: 1.4rem;
        background: #b39d19;
        padding: 16px 0;
        border-bottom: solid 1px #000;

        @include sp() {
          font-size: 1rem;
          padding: 8px 0;
        }
      }

      &--contents {
        background: #fff;
        font-size: 1.4rem;
        padding: 16px 0;
        color: #333;
        border-bottom: solid 1px #000;

        @include sp() {
          font-size: 1rem;
          padding: 8px 0;
        }
      }

      &--col:first-child {
        width: calc((100% /9) * 2);
        border-left: 0px;

        @include sp() {
          width: calc((100% /10) * 3);
        }
      }

      &__attention {
        margin: 30px 0 0;
        text-align: left;
        font-size: 1.4rem;
        line-height: 1.1;
        line-height: 1.8;

        @include tab() {
          margin: 15px 0 0;

          @include sp() {
            font-size: 1rem;
            margin: 0 0 25px;
          }
        }
      }
    }
  }


  &__navi {
    width: 50%;
    display: flex;

    @include tab() {
      display: none;
    }

    &--wrap {
      width: 50%;
      padding: 0 0 0 50px;
    }

    &__item {
      display: block;
      font-weight: bold;
      color: #fff;
      font-size: 1.6rem;
      margin: 0 0 24px;
      line-height: 1.4;

      @include otherSp() {
        &:hover {
          text-decoration: underline;
        }
      }

      &__lower {
        &--wrap {
          margin: 0 0 24px;
        }

        color: #fff;
        display: block;
        font-size: 1.4rem;
        margin: 14px 0 0;
        padding: 0 0 0 30px;
        line-height: 1.4;

        @include otherSp() {
          &:hover {
            text-decoration: underline;
          }
        }
      }

      & ul {
        margin: 30px 0 0;
      }
    }
  }

  &__spNavi {

    display: flex;
    width: 100vw;
    background: #fff;
    justify-content: space-between;
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 6;
    flex-wrap: wrap;

    @include otherSp() {
      display: none;
    }

    &__item {
      width: calc(25% - (3px / 4));
      background: $baseColor;
      text-align: center;
      padding: 10px 0;
      display: flex;
      align-items: center;
      justify-content: center;

      &--inner {
        color: #fff;
      }

      &__img {
        display: block;
        width: 23px;
        margin: 0 auto 10px;
      }

      &__text {
        font-size: 1.3rem;
        font-weight: bold;
      }
    }
    &__attention{
      width: 100%;
      padding: 5px;
      font-size: 1.2rem;
      line-height: 1.4;
      color: $subColor;
      text-align: center;
      font-weight: bold;

      @include otherSp() {
        display: none;
      }
    }
  }

  &__copyWright {
    position: absolute;
    bottom: 20px;
    left: 0;
    right: 0;
    margin: auto;
    text-align: center;

    &--top{
      @include otherSp() {
        width: 1.2rem;
        height: 1.2rem;
        font-size: 1.2rem;
        text-align: right;
        white-space: nowrap;
        position: absolute;
        top: 430px;
        right: 20px;
        transform: rotate(-90deg);
        z-index: 2;
        font-style: italic;
      }

      @include sp() {
        font-style: initial;
        margin: 0 -25px;
        width: 100vw;
        font-size: 1rem;
        text-align: center;
        padding: 15px 0 95px;
        color: #666;
        background: #fff;
      }
    }
  }
}
