.table {
  margin: 40px 0;

  @include sp() {
    margin: 20px 0;
  }

  &--wrap {
    display: flex;
    flex-wrap: wrap;
    border: solid 1px #f0f0ee;
    border-bottom: 0;

    @include sp() {
      display: block;
    }
  }

  &__title {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
    background-color: #f8f8f8;
    border-bottom: solid 1px #f0f0ee;
    border-right: solid 1px #f0f0ee;
    font-size: 1.6rem;
    line-height: 2;
    text-align: center;

    @include sp() {
      padding: 10px;
      font-size: 1.5rem;
      border-right: 0;
      width: 100%;
      font-weight: bold;
    }
  }

  &__description {
    @extend .table__title;
    border-right: 0;
    background-color: #fff;

    @include sp() {
      font-weight: 400;
    }
  }
}
