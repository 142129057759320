.sideNavi {

  &--wrap{
    height: 100vh;
    overflow: hidden;
    position: fixed;
    top:0px;
    left: 0%;
    z-index: 10;
    width: $sideNaviWidth;

    &::-webkit-scrollbar{
    display:none;
    }

    @include otherSp{
      &:hover{
        width: 200 + $sideNaviWidth;
      }
    }
    @include sp() {
      overflow: initial;
      width: 100vw;
      height: auto;
    }
  }

  &--inner{
    height: 100vh;
    width: 100%;
    overflow-y: scroll;
    -ms-overflow-style:none;
    padding-right: 17px; /* Increase/decrease this value for cross-browser compatibility */
    box-sizing: content-box; /* So the width will be 100% + 17px */
    @include sp() {
      height: auto;
      width: 100vw;
    }
  }

  height: auto;
  width: $sideNaviWidth;
  min-height: 100vh;
  border: solid 1px #f0f0ee;
  padding: 0 0 20px;

  @include sp() {
    padding-right: 0;
    width: 0;
    border: 0;
    overflow: initial;
    min-height: auto;
    height: auto;
  }

  &__spheader {

    @include sp() {
      position: fixed;
      top: 0;
      left: 0;
      width: 100vw;
      display: flex;
      background: #fff;
      align-items: center;
    }

    &__tel {
      display: none;

      @include sp() {
        height: 34px;
        border-right: solid 1px #f0f0ee;
        padding: 0 25px 0 0;
        display: flex;
        align-items: center;
      }

      & .fa-phone {
        font-size: 1.5rem;
        color: $baseColor;
        display: block;
      }
    }
  }

  &__logo {
    width: 100%;
    height: 213px;
    padding: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: solid 1px #f0f0ee;

    @include otherSp() {
      &:hover {
        opacity: 0.8;
      }
    }

    @include sp() {
      height: auto;
      display: block;
      border: 0;
      width: auto;
      flex-grow: 1;
    }

    &--pc {
      @include sp() {
        display: none;
      }
    }

    &--sp {
      display: none;
      height: 30px;
      width: auto;

      @include sp() {
        display: block;
      }
    }

  }

  &__menu {
    padding: 35px 0 15px;

    // &:hover {
    //   width: 380px;
    //   padding-right: 167px;
    // }

    @include sp() {
      width: 100vw;
      position: absolute;
      top: 60px;
      z-index: 5;
      background: #fff;
      padding: 15px 0;
      height: calc(100vh - 145px);
      overflow: scroll;
        right: 0vw;
        display: none;


      &[data-active="true"] {
        border-top: solid 1px #f0f0ee;
        display: block;
      }
    }

    &__item {
      display: block;
      padding: 15px 0;
      text-align: center;
      font-size: 1.6rem;
      color: #333333;
      position: relative;

      @include otherSp() {

        &:hover {
          color: #fff;
          background: rgba(221, 198, 84, 0.8);
        }
      }
      &--sp{
        @include otherSp() {
          display: none;
        }
        @extend .sideNavi__menu__item;
      }

      &--pc{
        @extend .sideNavi__menu__item;

        @include sp{
          display: none;
        }
      }
    }

    &__lower {
      z-index: 10;
      display: none;

      @include otherSp() {
        position: absolute;
        left: 100%;
        top: 0;
      }

      &__item {
        display: block;
        width: 200px;
        padding: 12px 20px;
        text-align: center;
        font-size: 1.6rem;
        line-height: 1.4;
        color: #333333;
        background: rgba(255, 255, 255, 0.8);
        position: relative;
        margin: 0 0 0 1px;

        &--sp{
          @extend .sideNavi__menu__lower__item;

          @include otherSp() {
            display: none;
          }
        }

        @include sp() {
          width: 100%;
          background: #f0f0ee;
        }

        @include otherSp() {
          &:hover {
            color: #fff;
            background: rgba(221, 198, 84, 0.8);

            &:before {
              content: "";
              display: inline-block;
              position: absolute;
              width: 5px;
              height: 100%;
              left: -6px;
              top: 0;
              background: $subColor;
            }
          }
        }
      }
    }

  }

  &__contact {
    &--wrap {
      padding: 0 20px;

      @include sp() {
        display: none;
      }
    }


    &--btn {
      background: #e71f19;
      height: 34px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 1.2rem;
      color: #fff;

      @include otherSp() {
        &:hover {
          opacity: 0.8;
        }
      }

      &>img {
        display: block;
        margin: 0 10px 0 0;
      }

      &:first-child {
        margin: 0 0 5px 0;
      }
    }

    &__tel {
      margin: 20px 0;

      &--btn {
        display: block;
        font-size: 1.9rem;
        font-weight: bold;
        color: $baseColor;
        display: flex;
        justify-content: space-between;
        margin: 0 0 5px;
      }

      &__attention {
        font-size: 1.1rem;
        color: #666666;
      }
    }
  }
  &__search{
    padding: 0 20px;

    @include sp{
      display: none;
    }

    &__searchform{
      border: solid 1px #dcdcdc;
      position: relative;
      &--input{
        border: 0;
        line-height: 3rem;
        padding: 0 0 0 10px;
      }
      &--btn{
        border-style:none;
        position: absolute;
        height: 30px;
        width: 30px;
        top: 0;
        right: 5px;
        background-image: url('../img/search--btn.png');
        background-size: 14px;
        background-position: center;
        background-repeat: no-repeat;
      }
    }
  }
}

@include otherSp() {
  .sideNavi__menu__trigger:hover {
    &>.sideNavi__menu__lower {
      display: block;
    }
  }
}

.sideNavi__menu__trigger {
  position: relative;

  @include sp() {

    &:after {
      content: "";
      display: inline-block;
      height: 10px;
      width: 10px;
      border-right: solid 1px $baseColor;
      border-bottom: solid 1px $baseColor;
      transform: rotate(45deg);
      position: absolute;
      right: 25px;
      top: 13px;
      transition: 0.4s;
    }

    &[data-active="true"] {
      &:after {
        @extend .sideNavi__menu__trigger:after;
        transform: rotate(-135deg);
        top: 17px;
      }
    }
  }
}
