html,
body,
h1,
h2,
h3,
h4,
ul,
ol,
dl,
li,
dt,
dd,
p,
div,
span,
img,
a,
table,
tr,
th,
td {
  margin: 0;
  padding: 0;
  border: 0;
  font-weight: normal;
  font-size: 100%;
  vertical-align: baseline;
  box-sizing: border-box;
}

article,
header,
footer,
aside,
figure,
figcaption,
nav,
section {
  display: block;
}

html {
  font-size: 62.5%;
  font-family: "Hiragino Kaku Gothic ProN", "メイリオ", sans-serif;
}

body {
  font-size: 1rem;
  line-height: 1;
}

ol,
ul {
  list-style: none;
  list-style-type: none;
}

a {
  text-decoration: none;
}
