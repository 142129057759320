.map {
  width: 100%;
  height: 500px;

  @include tab() {
    height: 250px;
  }

  &__iframe {
    height: 100%;
    width: 100%;
  }
  &__contents{
    margin: 40px auto 0;
    max-width: calc(1200px - 140px);

    @include sp{
    margin: 20px 25px 25px;
    }
    &__btn{
      display: block;
      width: 300px;
      margin: 0 auto 40px;
      padding: 15px;
      font-size: 1.4rem;
      text-align: center;
      background: #f8f8f8;
      color: #333;
      border: solid 1px #ddd;
      @include sp{
        margin: 0 auto 20px;
      }
      &:hover{
        color: #fff;
        background-color: #ddc654;
        border: solid 1px #ddc654;
      }
    }
    &__access{
      font-size: 1.6rem;
      line-height: 2;
      color: #333;
      margin: 0 0 40px;

      @include sp{
        font-size: 1.5rem;
        margin: 0 0 25px;
      }

    }
    &__photo{
      display: flex;
      justify-content: space-between;

      @include sp{
        display: block;
      }

      &__item{
        width: 33%;
        @include img;

        @include sp{
          width: 100%;
        }
      }
    }
  }
}
