.online {
  &__illustrated {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;

    &:after {
      content: '';
      display: block;
      width: calc((100% / 3) - 30px);

      @include sp() {
        width: 100%;
      }
    }

    &__item {
      width: calc((100% / 3) - 30px);
      margin: 25px 0;

      @include sp() {
        width: 100%;
        margin: 12px 0;
      }

      &--img {
        display: block;
        width: 100%;
        margin: 0 0 30px;

        @include sp() {
          width: 50%;
          margin: 0 25% 10px;
        }

        &--sp2col {
          @extend .online__illustrated__item--img;
          @include sp() {
            width: 100%;
            margin: 0 0 10px;
          }
        }
      }

      &--text {
        text-align: center;
        font-size: 1.6rem;
        line-height: 1.8rem;
        font-weight: bold;

        @include sp() {
          font-size: 1.2rem;
        }
      }

      &--sp2col {
        @extend .online__illustrated__item;

        @include sp() {
          width: calc((100% / 2) - 20px);
          margin: 12px 0;
        }
      }
    }
  }
}
