.policy {
  &__text {
    font-size: 1.6rem;
    line-height: 2;
    color: #333;
    margin: 0 0 30px;
  }

  &__link {
    display: flex;
    max-width: calc(1200px - 140px);

    @include tab() {
      display: block;
    }

    &__item {
      display: block;
      padding: 40px;
      background: #fff;
      color: #333;
      width: 50%;

      @include tab() {
        width: 100%;

        @include sp() {
          padding: 25px 15px;
        }
      }


      &__heading {
        font-size: 2rem;
        text-align: center;
        margin: 0 0 30px;

        @include sp() {
          font-size: 1.6rem;
          margin: 0 0 20px;
        }
      }

      &__text {
        text-align: center;
        font-size: 1.6rem;
        line-height: 2;

        @include sp() {
          font-size: 1.4rem;
          text-align: left;
          line-height: 1.6;
        }
      }

      &--color {
        @extend .policy__link__item;
        background: $subColor;
        color: #fff;
        border-radius: 10px;
        position: relative;

        @include sp() {
          font-weight: bold;
        }
      }
    }
  }

}
