// ----------

$widthTab: 1024px; //PC
$widthSp: 768px; //SP

@mixin pc {
  @media screen and (min-width: $widthTab + 1) {
    @content;
  }
}

@mixin tab {
  @media screen and (max-width: ($widthTab)) {
    @content;
  }
}

@mixin sp {
  @media screen and (max-width: ($widthSp)) {
    @content;
  }
}

@mixin otherSp {
  @media screen and (min-width: ($widthSp + 1)) {
    @content;
  }
}

@mixin img {
  & img {
    width: 100%;
  }
}

@mixin imgH {
  & img {
    height: 100%;
  }
}

@mixin hrefColor {
  color: $baseColor;
  text-decoration: underline;
}
