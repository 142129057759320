.mainVis {
  height: 660px;
  position: relative;
  background: #bbb;

  @include sp() {
    margin-top: 62px;
    height: 390px;
  }

  &__contact {
    position: fixed;
    top: 30px;
    right: 30px;
    padding: 25px 30px;
    color: #fff;
    z-index: 2;
    border: solid 1px #e71f19;
    background: #fff;

    &:before {
      content: "";
      position: absolute;
      right: auto;
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
      background: #e71f19;
      z-index: -1;
      transition: 0.1s;
    }

    @include sp() {
      position: absolute;
      top: auto;
      bottom: -45px;
      right: 50%;
      transform: translateX(50%);
      padding: 14px 15px;
    }

    &__header {
      font-size: 1.6rem;
      display: flex;
      justify-content: center;
      width: 250px;
      padding: 0 0 10px 0;
      margin: 0 0 10px 0;
      border-bottom: solid 1px #fff;
      align-items: center;

      @include sp() {
        font-size: 1.3rem;
        padding: 0 0 5px 0;
        margin: 0 0 5px 0;
      }

      & .fa-envelope {
        display: inline-block;
        font-size: 2rem;
        margin-right: 5px;
      }
    }

    &__tel {
      text-align: center;
      font-size: 1.1rem;

      @include sp() {
        font-size: 1rem;
      }

      & .tel {
        font-size: 1.3rem;
        font-weight: bold;

        @include sp() {
          font-size: 1.2rem;
        }
      }
    }

    &:hover {
      @include otherSp() {
        color: #e71f19;
      }

      &:before {
        @extend .mainVis__contact:before;

        @include otherSp() {
          width: 0%;
          right: 0;
          left: auto;
        }
      }

      & .mainVis__contact__header {
        @include otherSp() {
          border-bottom: solid 1px #e71f19;
        }
      }
    }
  }

  &__text {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 2;
    padding: 120px 0px 40px 70px;
    width: calc(100% - 60px);

    @include tab() {
      padding: 60px 25px 30px;
    }

    &__header {
      display: block;
      font-size: 1.4rem;
      color: #dddddd;

      @include sp() {
        font-size: 1.2rem;
        margin: 0 0 5px;
      }
    }

    &__title {
      display: block;
      font-size: 3.8rem;
      line-height: 1.8;
      color: #fff;

      @include sp() {
        font-size: 2rem;
        line-height: 1.6;
      }
    }
  }

  &__slider {
    width: 100%;
    height: 100%;
    z-index: 1;
    overflow: hidden;

    &--img {
      width: 100%;
      height: 660px !important;
      border: 0 !important;
      background-size: cover;
      background-position: center;

      @include sp() {
        height: 390px !important;
      }
    }
  }
}

.slick-dots {
  position: absolute;
  right: 0px;
  bottom: 0px;
  width: 100%;
  padding: 200px 0 10px 0;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0));

  @include sp() {
    padding: 10px 0 200px;
    transform: translateY(calc((100% - 210px) / 2));
  }

  &>li {
    margin: 10px 20px;
    text-align: right;

    @include sp() {
      margin: 5px 10px;
    }
  }

  & button {
    font-size: 0;
    border: 0;
    height: 13px;
    width: 13px;
    display: inline-block;
    border-radius: 100%;
    background: #f8f8f8;
    padding: 0;

    @include sp() {
      height: 6px;
      width: 6px;
    }
  }

  & .slick-active {
    & button {
      background: $subColor;
    }
  }
}
