@import "reset";
@import "rule";
@import "mixin";

@import "_about.scss";
@import "_aboutMaleInfertility.scss";
@import "_checkList.scss";
@import "_contact.scss";
@import "_contents.scss";
@import "_datepicker.scss";
@import "_description.scss";
@import "_doctor.scss";
@import "_feature.scss";
@import "_footer.scss";
@import "_hamberger.scss";
@import "_linkArea.scss";
@import "_lowerHeader.scss";
@import "_mainVis.scss";
@import "_map.scss";
@import "_mixin.scss";
@import "_online.scss";
@import "_policy.scss";
@import "_reset.scss";
@import "_rule.scss";
@import "_sideNavi.scss";
@import "_table.scss";
@import "sitemap.scss";

.page-wrap {
  padding: 0 0 0 ($sideNaviWidth + 1);
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;

  @include sp() {
    padding:0;
  }
}

.fa-phone {
  transform: rotate(90deg);
  font-size: 0.9em;
}

.pagetop {
  width: 40px;
  height: 40px;
  border-radius: 3px;
  position: fixed;
  bottom: 30px;
  right: 70px;
  background: #fff;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.2);

  &:hover {
    opacity: 0.8;
  }

  @include sp() {
    display: none;
  }

  &:after {
    content: "";
    display: inline-block;
    height: 0;
    width: 0;
    border-bottom: solid 10px #e71f19;
    border-left: solid 7px #fff;
    border-right: solid 7px #fff;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
  }
}
