.doctor {
  display: flex;
  align-items: center;

  @include tab() {
    display: block;
  }
  &--staff{
    display: flex;
    margin: -80px 0 0;
    padding: 80px 0 80px;
    @include tab() {
      display: block;
    }
  }

  &__img {
    width: 314px;
    background: url('../img/doctor__img.jpg');
    background-position: center;
    background-size: cover;

    @include tab() {
      width: 100%;
      margin: 0 0 15px;
    }

    &--staff{
      width:240px;

      @include tab() {
        width: 100%;
        margin: 0 0 15px;
        text-align: center;
      }
    }


    &:after {
      content: "";
      display: block;
      padding-top: 125%;

      @include tab() {
        padding-top: 60%;
      }
    }
  }

  &__biography {
    padding: 0 0 0 50px;
    flex: 1;
    color: #333;

    @include tab() {
      padding: 0;
    }

    &--btn{
      width: 300px;
    }

    &--name {
      font-size: 2rem;
      font-weight: bold;
      margin: 0 0 10px;

      @include sp() {
        font-size: 1.5rem;
      }
    }

    &--belong {
      font-size: 1.6rem;
      margin: 0 0 3.2rem;

      @include sp() {
        font-size: 1.5rem;
        margin: 0 0 3rem;
      }
    }

    &--comment {
      font-size: 1.6rem;
      line-height: 2;

      @include sp() {
        font-size: 1.5rem;
        margin: 0 0 25px;
      }
    }
  }
  &__career{
    margin: 40px 0 0;
    border-top: solid 1px #ddd;
    padding: 20px 0 0 ;
    &__heading{
      font-size: 1.6rem;
      margin: 0 0 20px;
      color: $subColor
    }
    &__list{
      display: flex;
      flex-wrap: wrap;
      &__title{
        width: 100px;
        font-size: 1.4rem;
        line-height: 2;
        color: #333;
        border-bottom: 2px solid $baseColor;
      }
      &__description{
        width: calc(100% - 100px);
        font-size: 1.4rem;
        line-height: 2;
        color: #333;
        border-bottom: 1px dotted #aaa;
        &--width100{
          @extend .doctor__career__list__description;
          width: 100%;
        }
      }
    }
  }
}
