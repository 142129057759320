.lowerHeader {
  @include sp() {
    margin: 60px 0 0;
  }

  &__breadcrumbs {
    background-color: #f7f3da;
    display: flex;
    align-items: center;
    padding: 20px 50px;

    @include sp() {
      padding: 10px 25px;
    }


    &__home {
      display: inline-block;
      height: 14px;
      @include imgH();

      @include sp() {
        display: none;
      }

    }

    &__current{
      font-size: 1.2rem;
      color: #333;
      display: flex;
      align-items: center;

      &:before {
        content: "";
        display: inline-block;
        height: 4px;
        width: 4px;
        border-right: solid 1px #999;
        border-top: solid 1px #999;
        transform: rotate(45deg);
        margin: 0px 20px;
      }

      @include sp() {
        &:before {
          order: 2;
          height: 2px;
          width: 2px;
          transform: rotate(135deg);
          margin: 0px 10px;
        }
      }
    }

    &__link {
      @extend .lowerHeader__breadcrumbs__current;
      &:hover{
        color: $baseColor;
      }
    }
  }

  &--wrap {
    padding: 150px 70px 50px;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    @include sp() {
      display: block;
      padding: 50px 15px 50px;
      text-align: center;
    }
  }

  &__title {

    &--en {
      display: block;
      margin: 0 0 20px;
      color: #bbb;
      font-size: 1.6rem;

      @include sp() {
        font-size: 1.1rem;
        margin: 0 0 10px;
      }
    }

    &--jp {
      font-size: 3.2rem;
      line-height: 1;
      font-weight: bold;
      letter-spacing: 0.5rem;

      @include sp() {
        font-size: 2.2rem;
        margin: 0 0 25px;
      }

      &:first-letter {
        color: $subColor;
      }
    }
  }

  &__description {
    text-align: right;
    font-size: 1.6rem;
    line-height: 2;
    color: #333;

    & a{
      @include hrefColor;
    }

    @include sp() {
      text-align: center;
      font-size: 1.5rem;
      line-height: 1.8;
    }
  }
}
