.hamburger {
  display: none;

  @include sp() {
    display: block;
    padding: 25px;
  }

  &--inner {
    width: 15px;
    height: 12px;
    position: relative;
  }

  &--border {
    width: 100%;
    height: 2px;
    border-radius: 1px;
    background: #555;
    position: absolute;
    transform: translateY(-50%);
    transition: 0.4s;

    &:nth-child(1) {
      @extend .hamburger--border;
      top: 0;
    }

    &:nth-child(2) {
      @extend .hamburger--border;
      top: 50%;
    }

    &:nth-child(3) {
      @extend .hamburger--border;
      top: 100%;
    }
  }

  &[data-active="true"] {
    & .hamburger--border {
      &:nth-child(1) {
        @extend .hamburger--border;
        top: 50%;
        transform: translateY(-50%) rotate(-45deg);
      }

      &:nth-child(2) {
        @extend .hamburger--border;
        top: 50%;
        opacity: 0;
      }

      &:nth-child(3) {
        @extend .hamburger--border;
        top: 50%;
        transform: translateY(-50%) rotate(45deg);
      }
    }
  }
}
