.about {
  background: #f8f8f8;
  padding: 50px 70px;

  @include tab() {
    padding: 25px;
  }

  &--inner {
    max-width: calc(1200px - 140px);
    margin: 0 auto;
  }

  &.pt70 {
    @include sp() {
      padding: 70px 25px 25px;
    }
  }

  &__heading {
    font-size: 2.4rem;
    padding: 0 0 0 10px;
    border-left: solid 4px $baseColor;
    margin: 0 0 33px;

    @include sp() {
      font-size: 1.7rem;
      padding: 0 0 0 5px;
      border-left: solid 2px $baseColor;
      margin: 0 0 15px;
    }
  }
}
