.sitemap{
  padding: 100px;
  &__list{
    margin: 0 0 0 20px;
  }
  &__item{
    font-size: 1.4rem;
    margin: 10px 0;
  }
}
