.contents {
  padding: 100px 0 0;

  @include tab() {
    margin: -60px 0 0 0;
    padding: 85px 0;
  }

  &--lower {
    @extend .contents;
    position: relative;

    &:before{
      content: "";
      display: block;
      position: absolute;
      width: 100%;
      top: 0;
      border-top: solid 1px #f0f0ee;

      @include sp{
        top: 60px;
      }
    }

    &__heading {
      text-align: center;
      margin: 0 auto 100px;
      max-width: calc(1200px - 140px);
      padding: 0 70px;

      @include sp() {
        margin: 20px 25px 50px;
        padding: 0;
      }

      &--text {
        display: inline;
        text-align: center;
        font-size: 3.4rem;
        line-height: 1.4;
        font-weight: bold;

        & br {
          display: none;
        }

        @include sp() {
          font-size: 1.9rem;
          line-height: 1.6;

          & br {
            display: block;
          }
        }

      }

      &__description {
        text-align: center;
        font-size: 2rem;
        line-height: 1.6;
        color: #333;
        margin: 50px 0 0;

        @include sp() {
          font-size: 1.5rem;
          margin: 25px 0 0;
        }
        &--attention {
          @extend .contents--lower__heading__description;
          color: #e71f19;
          margin: 0;
        }
        &--left{
          @extend .contents--lower__heading__description;
          text-align: left;
        }
      }
    }
  }

  &__article {
    margin: -40px auto 100px;
    max-width: calc(1200px - 140px);
    padding: 40px 70px 0;

    @include tab() {
      margin: 0 25px 25px;
      padding: 0;
    }

    &__post {

      & p {
        font-size: 1.6rem;
        line-height: 2;
        color: #333;

        & a{
          @include hrefColor;
        }

        @include tab() {
          font-size: 1.5rem;
          line-height: 1.8;
        }
      }

      & img {
        margin: 30px auto;
        display: block;
        max-width: 100%;
        height: auto;
      }
    }
  }

  &__heading {
    position: relative;
    margin: 0 0 20px;
    padding-top: 40px;

    @include tab() {
      margin: 20px 0 15px;
    }

    &:after {
      content: "";
      position: absolute;
      left: 0;
      bottom: 0;
      height: 1px;
      width: 100%;
      background: #ddd;
      z-index: -1;

    }

    &--text {
      font-size: 2.4rem;
      padding: 0 0 20px;
      display: inline-block;
      border-bottom: solid 1px $baseColor;
      line-height: 1.4;

      & a{
        position: relative;
        color: #333;
        border-bottom: solid 1px;

        &:hover{
          color: $subColor;
        }

        &:before{
          position: absolute;
          content: "";
          width: 16px;
          height: 16px;
          background-color: $subColor;
          border-radius: 20px;
          right: -30px;
          top: 0;
          bottom: 0;
          margin: auto;
        }
        &:after{
          position: absolute;
          content: "";
          width: 0;
          height: 0;
          right: -26px;
          border-left:solid 6px #fff;
          border-top:solid 4px $subColor;
          border-bottom:solid 4px $subColor;
          top: 0;
          bottom: 0;
          margin: auto;
        }
      }

      @include tab() {
        font-size: 1.7rem;
        padding: 0 0 13px;
      }
    }
  }

  &__text {
    font-size: 1.6rem;
    line-height: 2;
    color: #333;

    @include tab() {
      font-size: 1.5rem;
      line-height: 1.8;
    }

    & p a{
      color: $subColor;
      text-decoration: underline;
    }

    & h2{
      font-size: 1.8rem;
      font-weight: bold;
      padding: 0 0 0 10px;
      border-left:solid 4px $baseColor;
      line-height: 1.4;
      margin: 20px 0 10px;
    }

    @include tab() {
      font-size: 1.6rem;
    }

    &__readmore{

    }
  }

  &__img {

    margin: 30px 0;
    width: 100%;
    height: 400px;
    overflow: hidden;
    text-align: center;
    position: relative;

    @include sp() {
      height: auto;

      &:after {
        content: '';
        display: block;
        padding-top: 60%;
      }
    }

    &>img {
      position: absolute;
      width: auto;
      height: 100%;
      left: 50%;
      transform: translateX(-50%);
    }

    &--center{
      margin: 30px 0;
      text-align: center;

      & img{
        max-width:100%;
        max-height: 400px;
      }

      &--href{
        text-align: center;
        margin: 30px 0;
        & img{
          width: auto;
          max-width:100%;
          max-height: 400px;
          height: auto;
        }
        &:hover{
          opacity: 0.8;
        }
      }
    }

    &--left{
      margin: 30px 0;
      & img{
        max-width: 100%;
      }
    }
  }

  &__pagination {
    margin: 200px auto 0;
    max-width: calc(1200px - 140px);
    padding: 20px 70px;
    display: flex;
    justify-content: space-around;
    background-color: #ebebeb;
    cursor: pointer;


    @include sp() {
      margin: 50px 25px 0;
      padding: 20px 25px;
    }

    &--before {
      font-size: 1.6rem;
      color: #333;
      text-decoration: underline;
      transform: translateX(-50%);
      position: relative;
      &:hover{
        color: $subColor;
      }

      @include sp() {
        font-size: 1.4rem;
        transform: initial;
      }

      &:before {
        content: '';
        position: absolute;
        height: 0;
        width: 0;
        top: 0;
        bottom: 0;
        right: calc(100% + 10px);
        margin: auto;
        border-top: solid 3px #ebebeb;
        border-right: solid 6px #333;
        border-bottom: solid 3px #ebebeb;
      }

      &--nolink {
        @extend .contents__pagination--before;
        text-decoration: none;
        color: #888;

        &:before {
          display: none;
        }
      }
    }

    &--after {
      @extend .contents__pagination--before;
      transform: translateX(50%);

      &:before {
        border-left: solid 6px #333;
        border-right: 0;
        left: calc(100% + 10px);
        right: auto;
      }

      &--nolink {
        @extend .contents__pagination--before;
        text-decoration: none;
        color: #888;

        &:before {
          display: none;
        }
      }
    }
  }

  &__item{
    border-bottom: solid 1px #f0f0ee;
    margin: 0 0 100px;
    &__permalink{
      display: block;
      width: 300px;
      margin:  40px auto 0;
      padding: 15px;
      font-size: 1.4rem;
      text-align: center;
      background: #f8f8f8;
      color: #333;
      border: solid 1px #ddd;
      @include sp{
        margin:  20px auto 0;
      }
      &:hover{
        color:#fff;
        background-color: $baseColor;
        border:solid 1px $baseColor;
      }
    }
  }

  &__facility{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 0 0 -50px;

    @include sp{
      display: block;
     margin: 0 0 -40px;
    }

    &__item{
      width: 49.5%;
      margin: 0 0 50px;
      @include img;

      @include sp{
        width: 100%;
        margin: 0 0 40px;
      }

      &__text{
        margin: 20px 0 0;
        font-size: 1.6rem;
        line-height: 2;
        color: #333333;

        @include sp{
          margin: 10px 0 0;
          font-size: 1.5rem;
          line-height: 1.8;
        }

      }

    }
  }
}
