.checkList {
  &__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 0 0 -1%;

    &__item {
      width: 100%;

      &--2col {
        @extend .checkList__list__item;
        width: 49.5%;
      }

      @include tab() {
        width: 100%;

      }

      &--text {
        padding: 12px 30px;
        display: flex;
        align-items: center;
        font-size: 1.4rem;
        color: #333;
        background: #f8f8f8;
        margin: 0 0 1%;
        line-height: 1.8;

        @include sp() {
          padding: 10px 15px;
        }
      }

      &--question {
        @extend .checkList__list__item--text;
        padding: 20px 30px;
        font-size: 1.8rem;
        margin: 0 0 5px;

        &:hover {
          cursor: pointer;
          background-color: #f7f3da;
        }

        @include sp() {
          font-size: 1.4rem;
          margin: 0 0 1%;
          line-height: 1.8;
        }
      }

      &--img {
        height: 22px;
        flex-shrink: 0;
        margin: 0 16px 0 0;

        @include sp() {
          height: 11px;
          width: 11px;
        }
      }

      &--hide {
        display: none;
        padding: 25px 65px 30px;
        font-size: 1.6rem;
        line-height: 2;
        color: #333;

        & a{
          @include hrefColor;
        }



        @include sp() {
          font-size: 1.4rem;
          padding: 10px 0 15px;
        }
      }
    }
  }
}
