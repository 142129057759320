.linkArea {
  padding: 100px 70px;

  @include tab() {
    padding: 25px;
  }

  &__banner {
    text-align: center;
    margin: 100px auto 0;
    max-width: calc(1200px - 140px);
    padding: 0 70px;


    &:hover {
      opacity: 0.8;
    }

    @include tab() {
      margin: 25px 0 0 0;
      padding: 0;
    }

    & img {
      max-width: 100%;
    }
  }
}

.link {
  display: flex;
  max-width: calc(1200px - 140px);
  margin: auto;


  @include sp() {
    display: block;
  }

  &>li {
    width: calc(100% / 3);
    cursor: pointer;

    @include sp() {
      width: 100%;
    }
  }

  &__item {
    display: block;
    padding: 70px 0;
    background: #f8f8f8;
    transition: 0.4s;
    position: relative;

    &:after {
      content: "";
      position: absolute;
      display: block;
      height: 16px;
      width: 60px;
      transform: translateX(-50%);
      left: 50%;
      bottom: 0;
      background-image: url('../img/link__item--hover.png');
      background-repeat: no-repeat;
      background-size: contain;
      transition: 0.4s;
      opacity: 0;
    }

    @include sp() {
      padding: 22px 0;
      position: relative;
      margin: 0 0 5px;
    }


    &__img {
      height: 46px;
      width: 46px;
      margin: 0 auto 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;

      @include sp() {
        position: absolute;
        margin: 0;
        height: 23px;
        width: 23px;
        top: 50%;
        left: 36px;
        transform: translateY(-50%);
      }

      &--reservation {
        @extend .link__item__img;
        background-image: url('../img/link__item__img--reservation.png');
      }

      &--consultation {
        @extend .link__item__img;
        background-image: url('../img/link__item__img--consultation.png');
      }

      &--contact {
        @extend .link__item__img;
        background-image: url('../img/link__item__img--contact.png');
      }

    }

    &__heading {
      font-size: 2rem;
      margin: 0 0 10px;
      text-align: center;
      color: #333;
    }

    &__text {
      text-align: center;
      font-size: 1.2rem;
      color: #333;

      @include sp() {
        font-size: 1.5rem;
        color: #666;
      }
    }

    &:hover {
      background: $subColor;
      border-radius: 10px;
      position: relative;
      transition: 0.4s;

      & .link__item__heading {
        color: #fff;
      }

      & .link__item__text {
        color: #fff;
      }

      &:after {
        @extend .link__item:after;
        opacity: 1;
      }

      & .link__item__text {
        @include sp() {
          color: #fff;
        }
      }

      & .link__item__img--reservation {
        @extend .link__item__img;
        background-image: url('../img/link__item__img--reservation-w.png');
      }

      & .link__item__img--consultation {
        @extend .link__item__img;
        background-image: url('../img/link__item__img--consultation-w.png');
      }

      & .link__item__img--contact {
        @extend .link__item__img;
        background-image: url('../img/link__item__img--contact-w.png');
      }
    }
  }
}
